import { useScreenStore, useSlidesStore, useMainStore } from '@/store'
import { enterFullscreen, exitFullscreen, isFullscreen } from '@/utils/fullscreen'
import { storeToRefs } from 'pinia'

export default () => {
  const screenStore = useScreenStore()
  const slidesStore = useSlidesStore()
  const mainStore = useMainStore()
  const { isReview } = storeToRefs(mainStore)

  // 进入放映状态（从当前页开始）
  const enterScreening = () => {
    enterFullscreen()
    screenStore.setScreening(true)
  }

  // 进入放映状态（从第一页开始）
  const enterScreeningFromStart = () => {
    slidesStore.updateSlideIndex(0)
    enterScreening()
  }

  // 退出放映状态
  const exitScreening = () => {
    screenStore.setScreening(false)
    if (isReview.value) {
      mainStore.setIsReview(false)
      parent.postMessage(
        'close',
        process.env.VUE_APP_WEBHOK_URL
      )
    }
    
    if (isFullscreen()) exitFullscreen()
  }

  return {
    enterScreening,
    enterScreeningFromStart,
    exitScreening,
  }
}
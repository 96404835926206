import OSS from 'ali-oss'

export async function uploadImageForPPT(fileName, files) {
  const ossConfig = localStorage.getItem('oss')
  const {accessKeyId, accessKeySecret, ossBucket, ossBucketUrl} = JSON.parse(ossConfig)
  const oss = new OSS({
    region: 'oss-accelerate',
    accessKeyId,
    accessKeySecret, 
    bucket: ossBucket,
    secure: true
  })
  await oss.multipartUpload(fileName, files)
  const imgUrl = `${ossBucketUrl}${fileName}`
  return imgUrl
}

export function dataURLtoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}
/* eslint-disable max-lines */

import { Slide } from '@/types/slides'

function getPptPartName() {
  const pptPartName = localStorage.getItem('pptPartName')
  console.log('模板', pptPartName)
  return pptPartName
}
function pptUserName() {
  const pptUserName = localStorage.getItem('pptUserName')
  console.log('模板', pptUserName)
  return pptUserName
}
export const layouts: Slide[] = [
  {
    'id': 'GjK27SIb3c',
    'elements': [
      {
        'type': 'text',
        'id': 'U3w4mrnzRH',
        'left': 64.28509955848926,
        'top': 75.19650780293757,
        'width': 398.3432418254939,
        'height': 140,
        'content': '<p style=\'\'><strong><span style=\'font-size: 80px\'>选题模板</span></strong></p>',
        'rotate': 0,
        'defaultFontName': 'Microsoft Yahei',
        'defaultColor': '#333'
      },
      {
        'type': 'line',
        'id': 'PBpCQ_Ed-m',
        'left': 79.19413425477224,
        'top': 215.19650780293756,
        'start': [
          0,
          0.8427794867472925
        ],
        'end': [
          121.36024609161014,
          0
        ],
        'points': [
          '',
          ''
        ],
        'color': '#ff1e02',
        'style': 'solid',
        'width': 13
      },
      {
        content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
        defaultColor: '#333',
        defaultFontName: 'Microsoft Yahei',
        height: 47,
        id: 'P6BJHrVP5O',
        left: 11.046957405740564,
        rotate: 0,
        top: 521.7602150391023,
        type: 'text',
        width: 698.9004602603818
      }
    ],
    'background': {
      'type': 'image',
      'color': '#fff',
      'image': 'https://dev.oss.hokkj.cn/hok_product/pptsite/1662346836434.jpg',
      'imageSize': 'cover'
    }
  },
  {
    'id': 'test-slide-1',
    'elements': [
      {
        'type': 'text',
        'id': 'rrn0OMkxuS',
        'left': 150.5497698698091,
        'top': 44.00133354488719,
        'width': 698.9004602603818,
        'height': 50,
        'content': '<p style=\'\'><span style=\'font-size: 18px\'>请输入标题</span></p>',
        'rotate': 0,
        'defaultFontName': 'Microsoft Yahei',
        'defaultColor': '#333'
      },
      {
        content: '<p style=\"\">&nbsp;</p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p>',
        defaultColor: '#333',
        defaultFontName: 'Microsoft Yahei',
        height: 457,
        id: 'znKtJQBCki' + new Date().getTime(),
        left: 23.41598454712362,
        rotate: 0,
        top: 98.03874694002447,
        type: 'text',
        width: 956.2423500611994
      },
      {
        content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
        defaultColor: '#333',
        defaultFontName: 'Microsoft Yahei',
        height: 47,
        id: 'P6BJHrVP5O',
        left: 11.046957405740564,
        rotate: 0,
        top: 521.7602150391023,
        type: 'text',
        width: 698.9004602603818
      }
    ],
    'background': {
      'type': 'image',
      'color': '#ffffff',
      'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
      'imageSize': 'cover'
    }
  },
  {
    'id': 'test-slide-1',
    'elements': [
      {
        'type': 'text',
        'id': 'B1DK5AXeTB',
        'left': 87.46470860899248,
        'top': 104.25,
        'width': 845.3078252075345,
        'height': 424,
        'content': '<p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★话题：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★观点参考：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★转化用户：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★转化课程：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p>',
        'rotate': 0,
        'defaultFontName': 'Microsoft Yahei',
        'defaultColor': '#333'
      },
      {
        content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
        defaultColor: '#333',
        defaultFontName: 'Microsoft Yahei',
        height: 47,
        id: 'P6BJHrVP5O',
        left: 11.046957405740564,
        rotate: 0,
        top: 521.7602150391023,
        type: 'text',
        width: 698.9004602603818
      }
    ],
    'background': {
      'type': 'image',
      'color': '#ffffff',
      'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
      'imageSize': 'cover'
    }
  },
  {
    'id': 'gkCLlmzmXR',
    'elements': [
      {
        'type': 'text',
        'id': '-ymOKKI8TM',
        'left': 90.13351031702557,
        'top': 82.89958633573525,
        'width': 845.3078252075345,
        'height': 434.3125,
        'content': '<p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★话题：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★金句：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★观点参考：</span></span></span></strong></p><p style=\'\'><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>观点拆解1：</span></span></span></p><p style=\'\'><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>观点拆解2：</span></span></span></p><p style=\'\'><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>选题：</span></span></span></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★总结：</span></span></span></strong></p>',
        'rotate': 0,
        'defaultFontName': 'Microsoft Yahei',
        'defaultColor': '#333',
        'lineHeight': 1.8
      },
      {
        content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
        defaultColor: '#333',
        defaultFontName: 'Microsoft Yahei',
        height: 47,
        id: 'P6BJHrVP5O',
        left: 11.046957405740564,
        rotate: 0,
        top: 521.7602150391023,
        type: 'text',
        width: 698.9004602603818
      }
    ],
    'background': {
      'type': 'image',
      'color': '#ffffff',
      'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
      'imageSize': 'cover'
    }
  },
  {
    'id': 'ltQQLdlJjh',
    'elements': [
      {
        'type': 'text',
        'id': 'ncqnqJaO0V',
        'left': 88.13351031702547,
        'top': 82.54277727737747,
        'width': 657.5243493040047,
        'height': 444.125,
        'content': '<p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★品牌：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★问题：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★拆解：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>1.</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>2.</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>3.</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★转化用户：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★转化课程：</span></span></span></strong></p>',
        'rotate': 0,
        'defaultFontName': 'Microsoft Yahei',
        'defaultColor': '#333',
        'lineHeight': 1.8
      },
      {
        'type': 'image',
        'id': 'CIwoI022Hf',
        'src': 'https://dev.oss.hokkj.cn/hok_product/pptsite/1662349333915.jpg',
        'width': 211.94130798807248,
        'height': 121.39676185392759,
        'left': 745.6578596210302,
        'top': 128.36040916066187,
        'fixedRatio': true,
        'rotate': 0
      },
      {
        'type': 'image',
        'id': 'pWR2kRrZS_',
        'src': 'https://dev.oss.hokkj.cn/hok_product/pptsite/1662349333915.jpg',
        'width': 211.94130798807248,
        'height': 121.39676185392759,
        'left': 745.6578596210302,
        'top': 357.75564896361533,
        'fixedRatio': true,
        'rotate': 0
      },
      {
        content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
        defaultColor: '#333',
        defaultFontName: 'Microsoft Yahei',
        height: 47,
        id: 'P6BJHrVP5O',
        left: 11.046957405740564,
        rotate: 0,
        top: 521.7602150391023,
        type: 'text',
        width: 698.9004602603818
      }
    ],
    'background': {
      'type': 'image',
      'color': '#ffffff',
      'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
      'imageSize': 'cover'
    }
  }
]

export function getLayouts() {
  return [
    {
      'id': 'GjK27SIb3c',
      'elements': [
        {
          'type': 'text',
          'id': 'U3w4mrnzRH',
          'left': 64.28509955848926,
          'top': 75.19650780293757,
          'width': 398.3432418254939,
          'height': 140,
          'content': '<p style=\'\'><strong><span style=\'font-size: 80px\'>选题模板</span></strong></p>',
          'rotate': 0,
          'defaultFontName': 'Microsoft Yahei',
          'defaultColor': '#333'
        },
        {
          'type': 'line',
          'id': 'PBpCQ_Ed-m',
          'left': 79.19413425477224,
          'top': 215.19650780293756,
          'start': [
            0,
            0.8427794867472925
          ],
          'end': [
            121.36024609161014,
            0
          ],
          'points': [
            '',
            ''
          ],
          'color': '#ff1e02',
          'style': 'solid',
          'width': 13
        },
        {
          content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
          defaultColor: '#333',
          defaultFontName: 'Microsoft Yahei',
          height: 47,
          id: 'P6BJHrVP5O',
          left: 11.046957405740564,
          rotate: 0,
          top: 521.7602150391023,
          type: 'text',
          width: 698.9004602603818
        }
      ],
      'background': {
        'type': 'image',
        'color': '#fff',
        'image': 'https://dev.oss.hokkj.cn/hok_product/pptsite/1662346836434.jpg',
        'imageSize': 'cover'
      }
    },
    {
      'id': 'test-slide-1',
      'elements': [
        {
          'type': 'text',
          'id': 'rrn0OMkxuS',
          'left': 150.5497698698091,
          'top': 44.00133354488719,
          'width': 698.9004602603818,
          'height': 50,
          'content': '<p style=\'\'><span style=\'font-size: 18px\'>请输入标题</span></p>',
          'rotate': 0,
          'defaultFontName': 'Microsoft Yahei',
          'defaultColor': '#333'
        },
        {
          content: '<p style=\"\">&nbsp;</p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p><p style=\"\"><br class=\"ProseMirror-trailingBreak\"></p>',
          defaultColor: '#333',
          defaultFontName: 'Microsoft Yahei',
          height: 457,
          id: 'znKtJQBCki' + new Date().getTime(),
          left: 23.41598454712362,
          rotate: 0,
          top: 98.03874694002447,
          type: 'text',
          width: 956.2423500611994
        },
        {
          content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
          defaultColor: '#333',
          defaultFontName: 'Microsoft Yahei',
          height: 47,
          id: 'P6BJHrVP5O',
          left: 11.046957405740564,
          rotate: 0,
          top: 521.7602150391023,
          type: 'text',
          width: 698.9004602603818
        }
      ],
      'background': {
        'type': 'image',
        'color': '#ffffff',
        'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
        'imageSize': 'cover'
      }
    },
    {
      'id': 'test-slide-1',
      'elements': [
        {
          'type': 'text',
          'id': 'B1DK5AXeTB',
          'left': 87.46470860899248,
          'top': 104.25,
          'width': 845.3078252075345,
          'height': 424,
          'content': '<p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★话题：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★观点参考：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★转化用户：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★转化课程：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p>',
          'rotate': 0,
          'defaultFontName': 'Microsoft Yahei',
          'defaultColor': '#333'
        },
        {
          content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
          defaultColor: '#333',
          defaultFontName: 'Microsoft Yahei',
          height: 47,
          id: 'P6BJHrVP5O',
          left: 11.046957405740564,
          rotate: 0,
          top: 521.7602150391023,
          type: 'text',
          width: 698.9004602603818
        }
      ],
      'background': {
        'type': 'image',
        'color': '#ffffff',
        'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
        'imageSize': 'cover'
      }
    },
    {
      'id': 'gkCLlmzmXR',
      'elements': [
        {
          'type': 'text',
          'id': '-ymOKKI8TM',
          'left': 90.13351031702557,
          'top': 82.89958633573525,
          'width': 845.3078252075345,
          'height': 434.3125,
          'content': '<p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★话题：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★金句：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★观点参考：</span></span></span></strong></p><p style=\'\'><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>观点拆解1：</span></span></span></p><p style=\'\'><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>观点拆解2：</span></span></span></p><p style=\'\'><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>选题：</span></span></span></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: black;\'><span style=\'font-size: 18.0pt\'><span style=\'font-family: 微软雅黑\'>★总结：</span></span></span></strong></p>',
          'rotate': 0,
          'defaultFontName': 'Microsoft Yahei',
          'defaultColor': '#333',
          'lineHeight': 1.8
        },
        {
          content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
          defaultColor: '#333',
          defaultFontName: 'Microsoft Yahei',
          height: 47,
          id: 'P6BJHrVP5O',
          left: 11.046957405740564,
          rotate: 0,
          top: 521.7602150391023,
          type: 'text',
          width: 698.9004602603818
        }
      ],
      'background': {
        'type': 'image',
        'color': '#ffffff',
        'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
        'imageSize': 'cover'
      }
    },
    {
      'id': 'ltQQLdlJjh',
      'elements': [
        {
          'type': 'text',
          'id': 'ncqnqJaO0V',
          'left': 88.13351031702547,
          'top': 82.54277727737747,
          'width': 657.5243493040047,
          'height': 444.125,
          'content': '<p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★品牌：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★问题：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★拆解：</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>1.</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>2.</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>3.</span></span></span></strong></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★转化用户：</span></span></span></strong></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><br class=\'ProseMirror-trailingBreak\'></p><p style=\'\'><strong><span style=\'color: #262626;\'><span style=\'font-size: 16.0pt\'><span style=\'font-family: 微软雅黑\'>★转化课程：</span></span></span></strong></p>',
          'rotate': 0,
          'defaultFontName': 'Microsoft Yahei',
          'defaultColor': '#333',
          'lineHeight': 1.8
        },
        {
          'type': 'image',
          'id': 'CIwoI022Hf',
          'src': 'https://dev.oss.hokkj.cn/hok_product/pptsite/1662349333915.jpg',
          'width': 211.94130798807248,
          'height': 121.39676185392759,
          'left': 745.6578596210302,
          'top': 128.36040916066187,
          'fixedRatio': true,
          'rotate': 0
        },
        {
          'type': 'image',
          'id': 'pWR2kRrZS_',
          'src': 'https://dev.oss.hokkj.cn/hok_product/pptsite/1662349333915.jpg',
          'width': 211.94130798807248,
          'height': 121.39676185392759,
          'left': 745.6578596210302,
          'top': 357.75564896361533,
          'fixedRatio': true,
          'rotate': 0
        },
        {
          content: '<p style=\"\"><strong><span style=\"font-size: 18px\">' + getPptPartName() + '-' + pptUserName() + '</span></strong></p>',
          defaultColor: '#333',
          defaultFontName: 'Microsoft Yahei',
          height: 47,
          id: 'P6BJHrVP5O',
          left: 11.046957405740564,
          rotate: 0,
          top: 521.7602150391023,
          type: 'text',
          width: 698.9004602603818
        }
      ],
      'background': {
        'type': 'image',
        'color': '#ffffff',
        'image': 'https://dev.oss.hokkj.cn/hk1661830277187.png',
        'imageSize': 'cover'
      }
    }
  ]
}
/* 
{
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'vSheCJ',
        left: 183.5185185185185,
        top: 175.5092592592593,
        width: 605.1851851851851,
        height: 185.18518518518516,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0
      }, 
      {
        type: 'shape',
        id: 'Mpwv7x',
        left: 211.29629629629628,
        top: 201.80555555555557,
        width: 605.1851851851851,
        height: 185.18518518518516,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        opacity: 0.7
      }, 
      {
        type: 'text',
        id: 'WQOTAp',
        left: 304.9074074074074,
        top: 198.10185185185182,
        width: 417.9629629629629,
        height: 140,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 80px\'>感谢观看</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        wordSpace: 5
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'MZVO1kkj',
    elements: [
      {
        type: 'shape',
        id: 'cql0h8',
        left: 0,
        top: 0,
        width: 352.59259259259255,
        height: 562.5,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0
      },
      {
        type: 'shape',
        id: '_RTaF4',
        left: 171.4814814814814,
        top: 100.13888888888887,
        width: 362.22222222222223,
        height: 362.22222222222223,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: 'rgba(255,255,255,0)',
        fixedRatio: false,
        rotate: 0,
        outline: {
          width: 10,
          color: '{{backgroundColor}}',
          style: 'solid'
        }
      },
      {
        type: 'shape',
        id: 'UZfo8N',
        left: 216.66666666666663,
        top: 145.32407407407408,
        width: 271.85185185185185,
        height: 271.85185185185185,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{backgroundColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'font-size: 80px\'>01</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      },
      {
        type: 'text',
        id: 'ysqtBg',
        left: 561.4814814814814,
        top: 100.1388888888889,
        width: 359.25925925925924,
        height: 80,
        content: '<p style=\'\'><strong><span style=\'font-size: 40px\'>在此处输入标题</span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      },
      {
        type: 'text',
        id: 'lXsoHa',
        left: 572.5925925925925,
        top: 202.3611111111111,
        width: 257.77777777777777,
        height: 260,
        content: '<ol><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li></ol>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        lineHeight: 2,
        fill: '{{subColor}}'
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'EBBnTr',
        left: 360.5996472663139,
        top: 141.8496472663139,
        width: 278.80070546737215,
        height: 278.80070546737215,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 0,
          color: '{{backgroundColor}}',
          style: 'solid'
        }
      }, 
      {
        type: 'shape',
        id: 'gDIWDH',
        left: 456.4373897707231,
        top: 98.287037037037,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>1</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'shape',
        id: 'DUWT7E',
        left: 317.037037037037,
        top: 237.68738977072314,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>4</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'shape',
        id: 'pbhn38',
        left: 456.43738977072303,
        top: 377.08774250440916,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>3</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'shape',
        id: 'CvMKrO',
        left: 595.8377425044091,
        top: 237.6873897707231,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>2</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'adudHB',
        left: 402.962962962963,
        top: 39.39814814814815,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'text',
        id: '9UpDwg',
        left: 402.962962962963,
        top: 473.1018518518518,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'text',
        id: 'GERdpB',
        left: 111.48148148148151,
        top: 256.25,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'text',
        id: 'G5qoho',
        left: 691.1111111111111,
        top: 256.25,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'shape',
        id: 'vdZcI6',
        left: 415.18518518518516,
        top: 196.4351851851852,
        width: 169.62962962962962,
        height: 169.62962962962962,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{backgroundColor}}',
        fixedRatio: false,
        rotate: 0
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'tYUmrx',
        left: 156.66666666666683,
        top: 149.02777777777771,
        width: 264.4444444444445,
        height: 264.4444444444445,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'><span style=\'font-size: 60px\'>01</span></span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      },
      {
        type: 'shape',
        id: '0GVHf8',
        left: 342.2222222222223,
        top: 217.17592592592587,
        width: 128.14814814814812,
        height: 128.14814814814812,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{backgroundColor}}',
        fixedRatio: false,
        rotate: 0
      },
      {
        type: 'text',
        id: 'BO33Sv',
        left: 378.8888888888889,
        top: 235.24999999999994,
        width: 464.4444444444444,
        height: 92,
        content: '<p style=\'\'><strong><span style=\'font-size: 48px\'>在此处添加标题</span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'text',
        id: 'Hj7ttp',
        left: 69.35185185185185,
        top: 49.21759259259262,
        width: 420,
        height: 63,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 36px\'>1.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'FmKMNB',
        left: 69.35185185185185,
        top: 129.28240740740745,
        width: 420,
        height: 384,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'rI7ZeO',
        left: 510.64814814814815,
        top: 49.21759259259262,
        width: 420,
        height: 63,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 36px\'>2.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'KspwGc',
        left: 510.64814814814815,
        top: 129.28240740740745,
        width: 420,
        height: 384,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'text',
        id: 'Rx63Jo',
        left: 69.35185185185179,
        top: 51.71759259259262,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>1.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'ulyuzE',
        left: 69.35185185185179,
        top: 131.78240740740745,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'kr35Ca',
        left: 510.6481481481481,
        top: 51.71759259259262,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>2.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'BNQSpC',
        left: 510.6481481481481,
        top: 131.78240740740745,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'Vr38Nu',
        left: 69.35185185185185,
        top: 301.71759259259255,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>3.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'IwKRSu',
        left: 69.35185185185185,
        top: 381.7824074074074,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: '0Opr1v',
        left: 510.64814814814815,
        top: 301.71759259259255,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>4.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: '4L9Uzz',
        left: 510.64814814814815,
        top: 381.7824074074074,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'text',
        id: 'GdEGxg',
        left: 134.53703703703704,
        top: 127.25,
        width: 152.77777777777777,
        height: 308,
        lineHeight: 1.8,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 40px\'>请在此处输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        wordSpace: 8,
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'y5sAfw',
        left: 332.8703703703704,
        top: 127.25,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容1</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'VeuocM',
        left: 332.8703703703704,
        top: 212.0648148148148,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容2</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'RyFWQe',
        left: 332.8703703703704,
        top: 296.8796296296296,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容3</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'Q56viI',
        left: 332.8703703703704,
        top: 381.69444444444446,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容4</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'SUWirT',
        left: 73.8888888888889,
        top: 64.21296296296302,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>1</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'YjzN1M',
        left: 148.70370370370372,
        top: 64.21296296296302,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'fS09I7',
        left: 527.5925925925926,
        top: 64.21296296296302,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>2</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'qCnfB1',
        left: 602.4074074074074,
        top: 64.21296296296302,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      },
      {
        type: 'shape',
        id: 'difAAT',
        left: 73.8888888888889,
        top: 221.25000000000003,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>3</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'EUlvMo',
        left: 148.70370370370372,
        top: 221.25000000000003,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'US_9jB',
        left: 527.5925925925926,
        top: 221.25000000000003,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>4</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: '243MnQ',
        left: 602.4074074074074,
        top: 221.25000000000003,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'Y_KUj0',
        left: 73.8888888888889,
        top: 378.287037037037,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>5</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: '9GglMe',
        left: 148.70370370370372,
        top: 378.287037037037,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'eSInje',
        left: 527.5925925925926,
        top: 378.287037037037,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>6</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: '0S3yUg',
        left: 602.4074074074074,
        top: 378.287037037037,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },




*/